import {
  GetHeroCarouselComponentDocument,
  GetHeroCarouselComponentQuery,
  GetHeroCarouselComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';

import { getLocalesForGraphQL } from '../utils/helpers';

export async function getHeroCarouselComponent(id: string, locale: string) {
  return client.query<GetHeroCarouselComponentQuery, GetHeroCarouselComponentQueryVariables>({
    query: GetHeroCarouselComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
