import {
  GetCorpTimelineAccordionWrapperDocument,
  GetCorpTimelineAccordionWrapperQuery,
  GetCorpTimelineAccordionWrapperQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpTimelineAccordionWrapper(id: string, locale: string) {
  return client.query<
    GetCorpTimelineAccordionWrapperQuery,
    GetCorpTimelineAccordionWrapperQueryVariables
  >({
    query: GetCorpTimelineAccordionWrapperDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
