import {
  GetCorpMediaDownloadPreviewDocument,
  GetCorpMediaDownloadPreviewQuery,
  GetCorpMediaDownloadPreviewQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpMediaDownloadPreview(id: string, locale: string) {
  return client.query<GetCorpMediaDownloadPreviewQuery, GetCorpMediaDownloadPreviewQueryVariables>({
    query: GetCorpMediaDownloadPreviewDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
