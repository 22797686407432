import {
  GetCorpFormDocument,
  GetCorpFormQuery,
  GetCorpFormQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpForm(id: string, locale: string) {
  return client.query<GetCorpFormQuery, GetCorpFormQueryVariables>({
    query: GetCorpFormDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
