import styled from 'styled-components';

export const AnchorNavigationWrapper = styled.div<{
  $backgroundColor: string;
  children?: React.ReactNode;
  'data-test'?: string;
  className?: string;
}>`
  padding: 20px 0 10px 0;
  background-color: ${(props) => props.$backgroundColor || '#fff'};
  width: 100%;
  border-bottom: 1px solid #010e54;
  @media screen and (min-width: 768px) {
    padding-bottom: 0;
    text-align: center;
  }

  a {
    display: inline-block;
    margin: 0 20px;
    width: auto;
    color: #010e54;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 40px;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 25px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 100%;
      border-radius: 2em;
    }
    &:hover,
    &:focus {
      outline: none;
      &:after {
        background-color: #292b29;
      }
    }
  }
`;
AnchorNavigationWrapper.displayName = 'AnchorNavigationWrapper';

export const AnchorNavigationLinkList = styled.div`
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  > div {
    display: flex;
    flex-wrap: nowrap;
    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
    }
    > a {
      flex-shrink: 0;
    }
  }
`;
