import {
  GetIntroTextDocument,
  GetIntroTextQuery,
  GetIntroTextQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getIntroText(id: string, locale: string) {
  return client.query<GetIntroTextQuery, GetIntroTextQueryVariables>({
    query: GetIntroTextDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
