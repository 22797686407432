import {
  GetAnchorNavigationListDocument,
  GetAnchorNavigationListQuery,
  GetAnchorNavigationListQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';

export async function getAnchorNavigationList(id: string) {
  return client.query<GetAnchorNavigationListQuery, GetAnchorNavigationListQueryVariables>({
    query: GetAnchorNavigationListDocument,
    variables: {
      where: {
        id,
      },
    },
  });
}
