import {
  GetLocationComponentDocument,
  GetLocationComponentQuery,
  GetLocationComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getGenericPageComponents } from './getGenericPageComponents';
import { ITabs } from '../../components/Tabs/types';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getLocationComponent(
  id: string,
  locale: string,
  market: string | null,
): Promise<any> {
  let data: any;
  try {
    data = await client.query<GetLocationComponentQuery, GetLocationComponentQueryVariables>({
      query: GetLocationComponentDocument,
      variables: {
        locales: getLocalesForGraphQL(locale),
        where: {
          id,
        },
      },
    });
    // console.log('data', data.data?.locationComponent?.locationItems)
    if (
      data.data?.locationComponent?.locationItems &&
      data.data?.locationComponent?.locationItems.length > 0
    ) {
      const promiseCollector = data.data.locationComponent.locationItems.map(async (item: any) => {
        if (item.bodyComponents && item.bodyComponents.length > 0) {
          const bodyComponents = await getGenericPageComponents(
            item.bodyComponents,
            locale,
            market,
          );
          item.bodyComponents = bodyComponents;
        }
        return item;
      });
      data.data.locationComponent.locationItems = await Promise.all(promiseCollector);
    }
    return data;
  } catch (e) {
    throw new Error(`getLocationComponent error ${e}`);
  }
}
