import tw from 'tailwind-styled-components';
import { Text, Title } from './../Typography';
import styled from 'styled-components';
import { ReactNode, RefObject } from 'react';

export const GridContent = tw.div`
  text-center
  md:mb-30
  sm:mb-50
  mt-58
  sm:hidden
  md:block
`;
export const SubHeading = tw(Text)`
  mb-2.5
`;
export const Heading = tw(Title)`
  mb-5
  text-gray-300
`;
export const SearchOverlayWrapper = styled.div<{
  children?: ReactNode;
  className?: string;
  id?: string;
}>`
  z-index: 9;
  height: 100vh;
  width: 100vw;
  @media (max-width: 480px) {
    padding-bottom: 50px;
  }
`;
export const SearchOverlayWrapperStyle =
  'absolute w-full h-full left-0 z-30 bg-white overflow-auto md:top-header';

export const CloseButton = styled.button<{
  children?: ReactNode;
  className?: string;
  type?: string;
  onClick?: () => void;
}>`
  position: absolute;
  right: 16px;
  top: 18px;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 768px) {
    height: 32px;
    width: 32px;
    right: 26px;
    top: 28px;
  }
`;

export const SearchInput = styled.input<{
  children?: ReactNode;
  className?: string;
  type?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  ref?: RefObject<HTMLElement>;
  placeholder?: string;
  autoComplete?: string;
  autoFocus: boolean;
}>`
  height: 60px;
  padding-left: 30px;
  padding-right: 50px;
  @media (min-width: 768px) {
    height: 90px;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 28px;
  }
`;

export const HitWrapper = styled.a<{
  children?: ReactNode;
  className?: string;
  href?: string;
}>`
  max-width: 650px;
`;
