import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { ReactNode } from 'react';

export const DescriptionWrapper = styled.div<{
  children?: ReactNode;
  'data-test'?: string;
}>`
   {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }
`;

export const TitleWrapper = tw.div`
  pt-2.5
  lg:pb-7.5
  md:pb-5
  sm:pb-5
`;

export const RichTextWrapper = styled.div<{
  children?: ReactNode;
  className?: string;
}>`
  a {
    text-decoration: underline;

    &:focus,
    &:hover {
      color: #010e54;
    }
  }
`;

export const Link = styled.a<{
  children?: ReactNode;
  href?: string;
  target?: string;
}>``;
Link.displayName = 'Link';

export const ButtonContainer = styled.div<{
  children?: ReactNode;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
`;
ButtonContainer.displayName = 'ButtonContainer';
