import {
  GetCorpVideoHeroCarouselComponentDocument,
  GetCorpVideoHeroCarouselComponentQuery,
  GetCorpVideoHeroCarouselComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpVideoHeroCarouselComponent(id: string, locale: string) {
  return client.query<
    GetCorpVideoHeroCarouselComponentQuery,
    GetCorpVideoHeroCarouselComponentQueryVariables
  >({
    query: GetCorpVideoHeroCarouselComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
