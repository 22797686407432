import {
  GetCorpGenericAccordionDocument,
  GetCorpGenericContainerDocument,
  Scalars,
} from '../../generated/graphql';
import { client } from './client';
import { getGenericPageComponents } from './getGenericPageComponents';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpGenericContainer(id: string, locale: string, market: string | null) {
  let data: any;
  try {
    data = await client.query({
      query: GetCorpGenericContainerDocument,
      variables: {
        locales: getLocalesForGraphQL(locale),
        where: {
          id,
        },
      },
    });
    // It's a bit verbose but if we shorten it, it becomes difficult to read
    const item: any = data?.data?.corpGenericContainer?.content;
    const parsedContent = await getGenericPageComponents([item], locale, market);
    data.data.corpGenericContainer.content = parsedContent;
    return data;
  } catch (error: any) {
    console.error('Failed to fetch generic page with error:', error);
    return {};
  }
  return {};
}
