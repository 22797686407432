import { Text, Title } from './../Typography';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { ReactNode } from 'react';
export const CloseButton = styled.button<{
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
}>`
  height: 2.4rem;
  width: 2.4rem;
  background-color: white;
  border-radius: 100%;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  > span {
    height: 80%;
    width: 80%;
    font-size: 10%;
    display: inline-block;
    border-radius: 100%;
    border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    > svg {
      width: 90%;
      height: 90%;
    }
  }
`;
CloseButton.displayName = 'Container';

export const Description = styled(Text)`
  max-width: 600px;
  margin: auto;
`;

export const CustomTitle = styled(Title)`
  margin-bottom: 0.5rem;
  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`;
