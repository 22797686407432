import {
  GetBanner2Document,
  GetBanner2Query,
  GetBanner2QueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getBanner2(id: string, locale: string) {
  return client.query<GetBanner2Query, GetBanner2QueryVariables>({
    query: GetBanner2Document,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
