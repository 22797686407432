import {
  GetAuthorableContentCardDocument,
  GetAuthorableContentCardQuery,
  GetAuthorableContentCardQueryVariables,
  Scalars,
} from 'generated/graphql';
import { getLocalesForGraphQL } from '../utils/helpers';
import { client } from './client';

export async function getAuthorableContentCard(id: string, locale: string) {
  return client.query<GetAuthorableContentCardQuery, GetAuthorableContentCardQueryVariables>({
    query: GetAuthorableContentCardDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
