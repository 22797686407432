import { GetLinkDocument, GetLinkQuery, GetLinkQueryVariables, Scalars } from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getLink(id: string, locale: string) {
  return client.query<GetLinkQuery, GetLinkQueryVariables>({
    query: GetLinkDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
