import {
  GetGenericCardCarouseComponentDocument,
  GetGenericCardCarouseComponentQuery,
  GetGenericCardCarouseComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getGenericCardCarouseComponent(locale: string, id: string) {
  return client.query<
    GetGenericCardCarouseComponentQuery,
    GetGenericCardCarouseComponentQueryVariables
  >({
    query: GetGenericCardCarouseComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id,
      },
    },
  });
}
