import React from 'react';
import { Title, Text } from './../Typography';
import { Grid, GridItem, GridRow } from './../Grid';
import { NodeRendererType, RichText } from '@graphcms/rich-text-react-renderer';
import { DescriptionWrapper, RichTextWrapper, TitleWrapper } from './styles';
import { IIntroTextProps } from './types';
import { ButtonContainer, Link } from './styles';
import { CorporateButton } from './../CorporateButton';

// custom renderer to fix semantics & styling
const customRenderers: NodeRendererType = {
  p: ({ children }) => {
    return (
      <Text tag="p" type="lg" className="test-md">
        {children}
      </Text>
    );
  },
  bold: ({ children }) => {
    return <strong>{children}</strong>;
  },
  italic: ({ children }) => {
    return <i>{children}</i>;
  },
};

export function IntroText({ link, title, description, titleTag, richText }: IIntroTextProps) {
  return (
    <Grid>
      <GridRow columns={16}>
        <GridItem colStart={4} colSpan={10} className={'text-center'}>
          {title && (
            <TitleWrapper>
              <Title data-test="title" tag={titleTag || 'h2'} type="md">
                {title}
              </Title>
            </TitleWrapper>
          )}
          {description && (
            <Title data-test="text-description" tag="p" type="xs">
              {description}
            </Title>
          )}
          <DescriptionWrapper data-test="description-wrapper">
            {richText && (
              <RichTextWrapper className="pt-2.5 lg:pb-7.5 md:pb-5 sm:pb-5">
                <RichText content={richText.raw} renderers={customRenderers} />
              </RichTextWrapper>
            )}
          </DescriptionWrapper>
          {link?.url && (
            <Link href={link.url} target={link.openInNewTab ? '_blank' : '_self'}>
              <span className="sr-only">{link.displayName}</span>
              <ButtonContainer>
                <CorporateButton
                  label={link.displayName}
                  type="button"
                  buttonType="button"
                  color="primaryalternative"
                />
              </ButtonContainer>
            </Link>
          )}
        </GridItem>
      </GridRow>
    </Grid>
  );
}
