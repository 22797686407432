import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Text } from '../Typography';
import { ReactNode } from 'react';

export const Container = tw.section`
  p-0
  max-w-none
`;

export const FormElements = tw.div`
  md:w-300
  sm:w-full
  inline-block
  align-top
  md:mr-6
  sm:mr-0
`;

export const CSButton = tw.a`
  border
  border-white
  rounded-3xl
  px-8
  md:py-4
  sm:py-5
  md:mt-5
  sm:mt-10
  w-auto
  inline-block
`;
export const FormLabel = tw(Text)`
  inline-block
  w-full
  pb-5
  form-text
`;
export const CountryBox = tw.ul`
  rounded-2xl
  w-full
  border
  border-white
  p-0
  max-h-countrySelectorHeight
  overflow-auto
  mt-2.5
  border-radius: 1rem;
`;
export const CountryList = styled.li<{
  children?: ReactNode;
  className?: string;
}>`
  min-height: 60px;
  border-bottom: 1px solid #fff;
  &:last-child {
    border-bottom: 0;
  }
  &.selected {
    background-color: #010e54;
    border-color: #ffffff;
    label {
      color: #010e54;
    }
  }
  &.is-corporate {
    &.selected {
      background-color: #fff;
      label {
        color: #010e54;
      }
    }
    &:hover {
      background-color: #fdfbf9;
      label {
        color: #010e54;
      }
    }
  }
`;
export const CountryListStyle = 'border-b border-white m-0 country-list';
export const CountryListStyleCorporate = 'border-b border-white m-0 country-list is-corporate';

export const Label = tw.label`
  block
  py-4
  px-6
  font-geomanist
  tracking-wider
  text-xs
  lg:text-sm
`;
export const LanguageBox = tw.ul`
  rounded-2xl
  w-full
  border
  border-white
  p-0
  max-h-countrySelectorHeight
  overflow-auto
  mt-2.5
`;
export const LanguageList = styled.li<{
  children?: ReactNode;
  className?: string;
}>`
  min-height: 60px;
  &:first-child {
  }

  &:last-child {
    border-bottom: 0;
  }
  &.selected {
    background-color: #010e54;
    label {
      color: #fff;
    }
  }
  &.is-corporate {
    &.selected {
      background-color: #010e54;
      label {
        color: #fff;
      }
      &:hover {
        background-color: red;
        color: #fff;
      }
    }
  }
`;
export const LanguageListStyle = 'border-b border-white m-0 language-list';
export const LanguageListStyleCorporate = 'border-b border-white m-0 language-list is-corporate';
export const Flag = tw.img`
  inline-block
  mr-6
`;
export const MainLabel = tw.label`
  inline-block
  w-full
  relative
`;
export const MainInput = styled.input<{
  $hasError?: boolean;
  type: string;
  name?: string;
  onClick: () => void;
  onChange?: (e: MouseEvent) => void;
  value?: string;
  placeholder?: string;
  autoComplete?: string;
  id?: string;
  className?: string;
}>`
  background-color: transparent;
  caret-color: transparent;
  cursor: pointer;
  &:focus {
    outline-style: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px white;
  }
`;
export const MainFlagIcon = tw.span`
  absolute
  left-5
  bottom-1.5
`;
